import axios from '@/util/request'
// 网课视频
// /mobile/content-info/pageVideoInfo
// 视频-获取分页列表
export const pageVideoInfo = (data) => {
    return axios({
      url: '/mobile/content-info/pageVideoInfo',
      method: 'post',
      data
    })
}
// /com/had-login/lecture-info/getSignUpLectureInfoPage
// app讲座信息管理查询列表-包含当前登录用户是否报名字段
export const getSignUpLectureInfoPage = (data) => {
    return axios({
      url: '/com/had-login/lecture-info/getSignUpLectureInfoPage',
      method: 'post',
      data
    })
}

// /com/had-login/lecture-info/getLectureInfoInfo
// 讲座信息管理查询详情
export const getLectureInfoInfo = (params) => {
  return axios({
    url: '/com/had-login/lecture-info/getLectureInfoInfo',
    method: 'get',
    params
  })
}

// /com/had-login/lecture-info-user/saveLectureInfoUser
// 报名人员管理新增/编辑 报名
export const saveLectureInfoUser = (data) => {
  return axios({
    url: '/com/had-login/lecture-info-user/saveLectureInfoUser',
    method: 'post',
    data
  })
}

// /mobile/content-info/getVideoDetail
// 视频-获取单个详情
export const getVideoDetail = (params) => {
  return axios({
    url: '/mobile/content-info/getVideoDetail',
    method: 'get',
    params
  })
}