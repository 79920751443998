<template>
  <div>
    <div>
      <el-radio-group
        v-model="radio1"
        class="header"
        @change="getSignUpLectureInfoPage"
      >
        <el-radio-button :label="null">全国</el-radio-button>
        <el-radio-button
          :label="item.name"
          v-for="item in provinceList"
          :key="item.id"
          >{{ item.name }}</el-radio-button
        >
      </el-radio-group>
      <div>
        <div
          v-for="item in lectruelist"
          :key="item.id"
          class="lectruelist"
          @click="godetail(item.id)"
        >
          <div class="img"></div>
          <div class="title">
            {{ item.lectureTitle }}
          </div>
          <div class="text time">讲座时间：{{ item.lectureTime }}</div>
          <div class="text">讲座地点：{{ item.lectureAddress }}</div>
          <div v-if="item.signInState != -1">
            <div>
              <div v-if="item.signInState == 2">
                <div class="button" v-if="item.signInState == 2">已签到</div>
              </div>
              <div v-else>
                <div class="button" v-if="item.signInState == 1">已报名</div>
              </div>
            </div>
          </div>
          <div v-else>
            <div>
              <div
                v-if="
                  new Date().getTime() <
                  new Date(
                    new Date(item.lectureTime).getFullYear() +
                      '/' +
                      (new Date(item.lectureTime).getMonth() + 1) +
                      '/' +
                      new Date(item.lectureTime).getDate() +
                      ' ' +
                      '23:00:00'
                  ).getTime() +
                    1000 * 60 * 60 * 24
                "
              >
                <div class="button button1">去报名</div>
              </div>
              <div v-else>
                <div class="button">已结束</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="pagination">
        <el-pagination
          background
          :hide-on-single-page="total <= 10"
          layout="prev, pager, next"
          :total="total"
          @current-change="handleChange"
          :current-page.sync="current"
          :page-size="size"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { getProvinceList } from "@/api/index.js";
import { getSignUpLectureInfoPage } from "@/api/lectureHall.js";
export default {
  data() {
    return {
      current: 1,
      size: 10,
      total: 0,
      provinceList: [], //省份
      radio1: null,
      lectruelist: [], //讲座
    };
  },
  mounted() {
    this.getProvinceList();
    this.getSignUpLectureInfoPage();
  },
  methods: {
    handleChange(page) {
      this.current = page;
      this.getSignUpLectureInfoPage();
    },
    godetail(id) {
      this.$router.push({
        path: "/lectureHall/lectureHalllecturelist/lecturedetial",
        query: { id: id },
      });
    },
    getSignUpLectureInfoPage() {
      getSignUpLectureInfoPage({ lectureRegion: this.radio1 }).then((res) => {
        if (res.code == 1) {
          let data = res.data || {};
          this.lectruelist = data.records || [];
        }
      });
    },
    getProvinceList() {
      getProvinceList({}).then((res) => {
        if (res.code == 1) {
          this.provinceList = res.data || [];
          this.provinceList = this.provinceList.filter(item => {
            return item.name != "台湾省" && item.name != "香港特别行政区" && item.name != "澳门特别行政区"
          })
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #1ab394;
}
/deep/.el-pager li:hover {
  color: #1ab394 !important;
}
.header {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.lectruelist {
  position: relative;
  cursor: pointer;
  width: 100%;
  height: 120px;
  box-sizing: border-box;
  margin-top: 25px;
  margin-bottom: 10px;
  border: 1px solid #f1f1f1;
  padding: 21px 10px 21px 80px;
  .img {
    width: 70px;
    height: 60px;
    position: absolute;
    top: 0px;
    left: 0px;
    background-image: url(../../assets/lecture/讲座.png);
  }
  .title {
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .text {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #999999;
  }
  .time {
    margin: 15px 0px;
  }
  .button {
    position: absolute;
    top: 63px;
    right: 11px;
    width: 84px;
    height: 28px;
    line-height: 28px;
    text-align: center;
    background: #e6e6e6;
    cursor: pointer;
    color: #fff;
    border-radius: 14px;
  }
  .button1 {
    background: #1ab394 !important;
  }
}
.lectruelist:hover {
  .img {
    background-image: url(../../assets/lecture/讲座报名.png);
  }
  .title {
    color: #1ab394;
  }
}
.pagination {
  margin-top: 40px;
}
/deep/.el-radio-button__inner {
  color: #666666 !important;
  width: 120px;
  height: 28px;
  line-height: 28px;
  padding: 0px !important;
  margin: 5px !important;
  border-radius: 5px !important;
}
/deep/.el-radio-button:first-child .el-radio-button__inner {
  border-left: 0px !important;
}
/deep/.el-radio-button__orig-radio:checked + .el-radio-button__inner {
  background-color: #1ab394 !important;
  color: #fff !important;
}
/deep/.el-radio-button__inner {
  border: 0px !important;
}
</style>
